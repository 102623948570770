import { Col, Row } from "antd";
import { EMPTY_VALUE, ISManagePaths } from "../../utils/const";
import { FormattedMessage } from "react-intl";
import { TruthAndFalseList } from "../../data";
import { TabInfoType } from "../../ts/types";
import { Translation } from "../../utils";

export default function TabInfo(props: TabInfoType) {
  const {
    translationKey,
    data,
    radius = false,
    mode = "normal",
    intl = null,
    payload,
  } = props;
  const isMissingEstablishment =
    payload?.tutorPreferences?.assignment?.establishments?.[0]?.isMissing ||
    payload?.assignment?.establishments?.[0]?.isMissing;
  if (mode === "raports-multiple")
    return (
      <div
        className={
          radius
            ? "row hp-py-20 border-top-left-radius border-top-right-radius"
            : "row hp-py-20"
        }
      >
        <Row>
          <Col span={8}>
            <span className="hp-fw-500">
              {Translation(translationKey) || EMPTY_VALUE}
            </span>
          </Col>

          <Col span={8} style={{ display: "flex", flexDirection: "column" }}>
            {data?.map((item: any, index: number) => {
              return (
                <div
                  key={index}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <p>{item?.fullName || EMPTY_VALUE}</p>
                </div>
              );
            })}
          </Col>

          <Col span={3}>
            {data?.map((item: any, index: number) => {
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <p className="mr-24 font-weight-500">
                    <FormattedMessage id="absence" />
                  </p>

                  <p className="font-size-15">
                    {TruthAndFalseList(intl).find(
                      (mode) => mode.value === item?.absence
                    )?.name || EMPTY_VALUE}
                  </p>
                </div>
              );
            })}
          </Col>
          {props.children}
        </Row>
      </div>
    );
  else
    return (
      <div
        className={
          radius
            ? "row hp-py-20 border-top-left-radius border-top-right-radius"
            : "row hp-py-20"
        }
      >
        <Row style={{ alignItems: "center" }}>
          <Col span={8}>
            <span className="hp-fw-500">
              {Translation(translationKey) || EMPTY_VALUE}
            </span>
          </Col>

          <Col
            onClick={() => {
              if (
                translationKey === "establishment" &&
                isMissingEstablishment
              ) {
                window.open(`${ISManagePaths.ESTABLISHMENTS}/add`, "_blank");
              }
            }}
            style={{
              color:
                translationKey === "establishment" && isMissingEstablishment
                  ? "red"
                  : undefined,
              textDecoration: isMissingEstablishment && "underline",
              cursor: "pointer",
            }}
            span={8}
          >
            {data}
          </Col>
          {props.children}
        </Row>
      </div>
    );
}
