import AvailabilityDay from "./availabilityDay";

import { FC, useState } from "react";
import { AVAILABILITIES } from "./availabilitiesData";

export type AvailabilitiesProps = {
  day: string;
  hours: Hour[];
}[];

export type Hour = {
  taken: boolean;
  selected: boolean;
  startTime: string;
  endTime: string;
};

export type AvailibilityProps = {
  setAvailabilitySelected: () => void;
};

type Props = {
  initialValues?: any;
  onSelect?: any;
  disabled?: boolean | null;
  initialAvailabilities?: AvailabilitiesProps;
  isUpdate?: boolean;
};

const Availability: FC<Props> = ({
  initialAvailabilities,
  initialValues,
  disabled,
  onSelect,

  isUpdate,
}) => {
  const [availabilities, setAvailabilities] = useState<AvailabilitiesProps>(
    initialAvailabilities || AVAILABILITIES
  );

  return (
    <div className="availability-week">
      {availabilities.map((week) => (
        <AvailabilityDay
          isUpdate={isUpdate}
          onSelect={onSelect}
          key={week.day}
          availabilities={availabilities}
          setAvailabilities={setAvailabilities}
          week={week}
          initialValues={initialValues}
          disabled={disabled}
        />
      ))}
    </div>
  );
};

export default Availability;
