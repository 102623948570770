import ReactDOM from "react-dom";
import store from "./redux/store";
import App from "./App";
import AuthContextProvider from "./context/AuthContext";
import AppStateProvider from "./context/appState";

import { Suspense, useEffect } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { Provider } from "react-redux";

import "./assets/icons/remixicon.css";
import "./assets/less/yoda-theme.less";
import "react-phone-input-2/lib/style.css";

import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

const queryClient = new QueryClient({});
// Lazy-load Sentry initialization
const initializeSentry = () => {
  if (process.env.NODE_ENV === "production") {
    const Sentry = require("@sentry/react");
    const { BrowserTracing, Replay } = Sentry;

    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DNS,
      environment: process.env.NODE_ENV,
      enabled: true,
      integrations: [
        new BrowserTracing({
          tracePropagationTargets: [
            "localhost",
            `${process.env.REACT_APP_SERVER_URL}`,
          ],
        }),
        new Replay(),
      ],
      tracesSampleRate: 0.5,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  }
};

// Call Sentry initialization
initializeSentry();
ReactDOM.render(
  <Suspense fallback="loading">
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <BrowserRouter>
          <AppStateProvider>
            <AuthContextProvider>
              <App />
            </AuthContextProvider>
          </AppStateProvider>
        </BrowserRouter>
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </Suspense>,
  // <Welcoming />
  document.getElementById("root")
);
