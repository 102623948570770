import moment from "moment";
import {
  CSVFormat,
  CsvDownloader,
  FormatDateToDay,
  FormatDateToHoursAndMinutes,
} from "../utils";
import {
  DAY_OF_THE_WEEK,
  DAY_OF_THE_WEEK_AND_WEEKENDS,
  MONTH,
  MONTH_SHORT,
  ON_BOARDING_STATUS,
  UserRoles,
  UserStatus,
} from "../utils/const";

export const emailValidation = (email: string) => {
  const regex =
    /^(([^<>()[\],;:\s@"]+(\.[^<>()[\],;:\s@"]+)*)|(".+"))@(([^<>()[\],;:\s@"]+\.)+[^<>()[\],;:\s@"]{2,})$/i;
  if (!email || regex.test(email) === false) return false;

  return true;
};

export const passwordValidation = (password: string) => {
  return /^(?=.*\d).{6,}$/.test(password);
};

export const getMonthLong = (value: number) => {
  let month;
  if (value) month = MONTH[value - 1];

  return month;
};

export const getMonthShort = (value: number) => {
  let month;
  if (month) {
    month = MONTH_SHORT[value - 1];
  }
  return month;
};

export const formatAvailabilities = (data: any) => {
  const AVAILABILITIES = DAY_OF_THE_WEEK.map((day, index) => {
    const matchingAvailabilities = data?.filter((availability: any) => {
      return availability.dayOfTheWeek === index;
    });

    const hours = matchingAvailabilities?.map((availability: any) => {
      return {
        selected: false,
        startTime: availability.startTime,
        endTime: availability.endTime,
        taken: availability.taken || false,
      };
    });

    return { day: day, hours: hours };
  });

  return AVAILABILITIES;
};

// [IS] - CRUD Messages
export const deleteConfirmationMessage = (
  name: any,
  entity: any,
  intl: any,
  ignoreQuotes: boolean = false
) => {
  const deletePlaceholder = intl.formatMessage({ id: "delete-confirmation" });

  if (ignoreQuotes === false)
    return `${deletePlaceholder} "${name}" ${entity}?`;
  else return `${deletePlaceholder} ${name} ${entity}?`;
};

export const deleteMessage = (entity: string, intl: any) => {
  const deletePlaceholder = intl.formatMessage({ id: "deleted-successfully" });
  return `${entity} ${deletePlaceholder}`;
};

export const updateMessage = (entity: string, intl: any) => {
  const updatePlaceholder = intl.formatMessage({ id: "updated-successfully" });
  return `${entity} ${updatePlaceholder}`;
};

export const addMessage = (entity: string, intl: any) => {
  const addPlaceholder = intl.formatMessage({ id: "added-successfully" });
  return `${entity} ${addPlaceholder}`;
};

export const errorAddMessage = (entity: string, intl: any) => {
  const errorAddPlaceholder = intl.formatMessage({ id: "couldnt-be-added" });
  return `${entity} ${errorAddPlaceholder}`;
};

export const errorUpdateMessage = (entity: string, intl: any) => {
  const errorUpdatePlaceholder = intl.formatMessage({
    id: "couldnt-be-updated",
  });
  return `${entity} ${errorUpdatePlaceholder}`;
};

export const publishLabel = (operation: string, entity: string) =>
  `Select publish label to ${operation} ${entity}`;

export const firstLetterToUpperCase = (value: string) => {
  if (value) return value?.charAt(0).toUpperCase() + value?.slice(1);

  return false;
};

export const generateSessionId = () => {
  const timestamp = Math.floor(new Date().getTime() / 100000); // get just 8 timestime second numbers
  const rand1 = Math.floor(Math.random() * 10); // generate a random number from 0-9
  const rand2 = Math.floor(Math.random() * 10); // generate a random number from 0-9
  const rand3 = Math.floor(Math.random() * 10); // generate a random number from 0-9

  return `ss-${rand1}-${rand2}${timestamp}${rand3}`;
};

const onboardingStepMap: { [key: string]: string } = {
  "1": "Formulaire d'inscription",
  "2": "Visionnage des vidéos",
  "3": "Passage du quizz",
  "5": "En attente de validation (succés quiz)",
  "6": "En attente de validation (échec quiz)",
  "7": "Validé",
};

export const getStepText = (step: string) =>
  onboardingStepMap[step] || "Unknown";

export const splitURL = (url: any) => {
  const parts = url.split("://");
  const protocol = parts[0] + "://";
  const host = parts[1];

  return [protocol, host];
};

export const redirectUrl = (url: string) => (window.location.href = url);

export const sessionURL = (host: string, sessionId: string) => {
  return `${host}/session/${sessionId}`;
};

export const removeHyphen = (value: string) => {
  let newValue = value.replace(/-/g, " "); // replace hyphen with an empty string
  return newValue;
};

export const sessionDate = (
  month: number,
  day: string,
  startHour: string,
  endHour: string
) => {
  let sessionDate = "N/A";
  if (startHour && endHour)
    sessionDate = `${getMonthLong(month)} ${FormatDateToDay(
      day
    )}, ${FormatDateToHoursAndMinutes(
      startHour
    )} - ${FormatDateToHoursAndMinutes(endHour)}`;

  return sessionDate;
};

export const sessionFullDate = (
  dayOfTheWeek: number,
  date: number,
  day: number,
  startHour: string,
  endHour: string
) => {
  let sessionFullDate = "N/A";
  if (startHour && endHour)
    sessionFullDate = `${DAY_OF_THE_WEEK_AND_WEEKENDS[dayOfTheWeek]}, ${
      MONTH[date - 1]
    } ${day} • ${startHour}:00 - ${endHour}:00`;

  return sessionFullDate;
};

export const sessionFullDateWithMinutes = ({
  dayOfTheWeek,
  date,
  day,
  start,
  end,
}: {
  dayOfTheWeek: number;
  date: number;
  day: number;
  start: {
    hour: string;
    minute: string;
  };
  end: {
    hour: string;
    minute: string;
  };
}) => {
  let sessionFullDate = "N/A";
  const pad = (n: string) => (Number(n) < 10 ? "0" + n : n);

  if (start.hour && start.minute && end.hour && end.minute) {
    sessionFullDate = `${DAY_OF_THE_WEEK_AND_WEEKENDS[dayOfTheWeek]}, ${
      MONTH[date - 1]
    } ${day} • ${pad(start.hour)}:${pad(start.minute)} - ${pad(end.hour)}:${pad(
      end.minute
    )}`;
  }

  return sessionFullDate;
};

export const checkTutorOnboardingStatus = (
  status: string,
  history: any,
  redirectUrl: string
) => {
  if (status === ON_BOARDING_STATUS.COMPLETED) history.push("/");
  else history.push(redirectUrl);
};
export const checkParentOnboardingStatus = (
  status: string,
  history: any,
  redirectUrl: string
) => {
  if (status.toLowerCase() === UserStatus.ACTIVE) history.push("/");
  else history.push(redirectUrl);
};
export const addStringBeforeAtSign = (
  email: string,
  index: number,
  parent: any
) => {
  if (email.includes(`+child-${index}@`)) {
    return email;
  }
  if (parent.contactDetails.email !== email) {
    return email;
  }

  return email.replace(/@/g, `+child-${index}@`);
};

export const filterMenusBasedOnProgram = (menus: any) => {
  const userData = localStorage.getItem("user");
  if (!userData) {
    console.error("User data is not available in local storage.");
    return menus;
  }
  const { administrationPreferences, userRole } = JSON.parse(userData);
  if (!administrationPreferences || !userRole) return menus;
  const programIds: string[] = administrationPreferences?.program?.map(
    (item: any) => item.programId
  );
  return menus.map((parentMenu: any) => {
    if (parentMenu.id === "manage") {
      parentMenu.children = parentMenu.children.filter((childMenu: any) => {
        if (
          programIds?.includes("az-fac-5f87232") &&
          [UserRoles.VSC, UserRoles.COORDINATOR].includes(userRole)
        ) {
          return childMenu.id !== "parents" && childMenu.id !== "matching";
        }
        return true;
      });
    }
    return parentMenu;
  });
};
export const mapPayloadToCsv = ({
  payload,
  config,
  intl,
  roleLabel,
  EMPTY_VALUE,
}: any) => {
  const csv = payload.map((item: any) => {
    const mappedItem: any = {};

    config.fields.forEach((field: any) => {
      if (field.type === "program") {
        mappedItem[field.label] =
          config
            .programsList(intl)
            .find((p: any) => p.programId === item?.program?.programId)
            ?.programName || EMPTY_VALUE;
      } else if (field.type === "status") {
        mappedItem[field.label] =
          config
            .statusList(intl)
            .find((s: any) => s.value === item[field.source])?.name ||
          EMPTY_VALUE;
      } else if (field.type === "date") {
        mappedItem[field.label] =
          moment(item[field.source])
            .format(field.format || "dddd, D MMMM YYYY, HH:mm")
            .replace(/,/g, " ") || EMPTY_VALUE;
      } else if (field.type === "situation") {
        mappedItem[field.label] =
          config
            .tutorStatusesList(intl)
            .find((s: any) => s.value === item[field.source])?.name ||
          EMPTY_VALUE;
      } else if (field.type === "folderStatus") {
        mappedItem[field.label] = item[field.source] ? "Complet" : "Incomplet";
      } else if (field.type === "onBoardingStep") {
        mappedItem[field.label] =
          getStepText(item?.onBoardingStep) || EMPTY_VALUE;
      } else {
        mappedItem[field.label] = item[field.source] || EMPTY_VALUE;
      }
    });
    return mappedItem;
  });
  const header = config?.fields
    ?.map(({ header }: any) => intl.formatMessage({ id: header }))
    .join(",");
  const csvColumns = csv
    .map((u: any) =>
      config.fields.map((field: any) => u[field.label]).join(",")
    )
    .join("\n");
  const csvData = header + "\n" + csvColumns;

  const csvName = `${roleLabel}-${CSVFormat()}.csv`;

  CsvDownloader(csvData, csvName);
};
