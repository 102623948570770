import { FC } from "react";
import { AvailabilitiesProps, Hour } from "./availability";
import AvailabilityTime from "./availabilityTime";

type Props = {
  availabilities: AvailabilitiesProps;
  setAvailabilities: (data: AvailabilitiesProps) => void;
  week: any;
  initialValues?: any;
  onSelect?: any;
  disabled?: boolean | null;
  isUpdate?: boolean;
};

const AvailabilityDay: FC<Props> = ({
  availabilities,
  isUpdate,
  setAvailabilities,
  week,
  initialValues,
  onSelect,
  disabled,
}) => {
  return (
    <>
      <div
        style={disabled === null ? { width: "50px" } : {}}
        className="availability-day"
        key={week.day}
      >
        <div
          style={
            disabled === null ? { padding: "6px 4.5px", fontSize: "11px" } : {}
          }
          className="availability-day-name"
        >
          {disabled === null ? week.day.slice(0, 3) : week.day}
        </div>
        <ul className="availability-time">
          {week?.hours?.map((hour: Hour, index: number) => (
            <AvailabilityTime
              key={index}
              isUpdate={isUpdate}
              availabilities={availabilities}
              setAvailabilities={setAvailabilities}
              day={week.day}
              hour={hour}
              index={index}
              initialValues={initialValues || []}
              onSelect={onSelect}
              disabled={disabled}
            />
          ))}
        </ul>
      </div>
    </>
  );
};

export default AvailabilityDay;
